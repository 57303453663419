<template>
  <list-container-widget
    id="contacts"
    :app-id="120"
    :view-more-location="getLocation('contacts')"
    :disable-view-more="disableViewMore"
    :size="addOnSize"
    :stretch="false"
  >
    <template v-if="showViewAllButton" #actions>
      <ViewAllButton 
        item-type="contacts" 
      />
    </template>
    
    <members-list
      v-if="canList"
      :per-page="perPage"
      enable-card-size="xl"
      :members="results"
      :is-loading="isLoading"
      :total="total"
      :organization-key="organizationKey"
      class="members-table"
      @edit="handleEditItem"
      @total-updated="setAddOnSize($event)"
      @handleLoadOfNewItems="handleLoadOfNewItems"
      @profile-updated="handleLoadOfNewItems(page= 1)"
    />
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="membersPlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t('no-permisions.message') }}
        </p>
      </b-col>
    </b-row>
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import MembersList from "@/views/apps/contacts/components/ContactsList.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
// import CreateContactsModal from '@/views/apps/contacts/components/CreateContactsModal.vue';
import { checkPermissions } from "@/@core/utils/roles-utils";
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";
// import OrganizationsAPI from "@/views/apps/organization/store/OrganizationsAPI";
import MembersSelect from "@/views/apps/member/components/MembersSelect.vue";

export default {
  name: "ContactsListWidget",
  components: {
    ListContainerWidget,
    MembersList,
    MembersSelect,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),

  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  props: {
    perPage: {
      type: Number,
      default: 8,
    },
    disableViewMore: Boolean,
    societyKey: {
      type: String,
      default: null,
    },
    organizationKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      page: 1,
      isLoading: false,
      index: 0,
      timer: null,
      results: {},
      currentPage: 1,
      isCreateModalOpended: false,
      modalTitle: '',
      areSearchVisible: false,
      currentContact: '',
    };
  },
  computed: {
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    canList() {
      return checkPermissions(
        "view",
        "contact",
        this.loggedMemberRoles,
        this.collective,
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    members() {
      return this.$store.getters.contacts.unpaginated || [];
    },
    total() {
      return this.$store.getters.contacts.meta?.total || 0;
    },
    showViewAllButton() {
      return !this.societyKey && !this.organizationKey;
    }
  },
  watch: {
    selectedOrganizationForFilter() {
      this.fetchData({ page: 1, searchString: '', forceAPICall: true });
    }
  },
  async created() {
    this.isLoading = true;
    await this.fetchData({
      page: this.currentPage,
      searchString: "",
      forceAPICall: true,
    });
    this.isLoading = false;
  },
  methods: {
    async fetchData({ page, searchString = '', forceAPICall = false }) {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: this.$route.name !== 'organization' && this.$route.name !== 'societies-details' ? "contacts" : 'organization/contacts',
        ...(this.$route.name === 'organization' || this.$route.name === 'societies-details' ? {customName: 'contacts'} : ''),
        page,
        forceAPICall,
        perPage: this.perPage,
        requestConfig: {
          ...(this.$route.name === 'organization' ? {organizationKey: this.$route.params.organizationId} : ''),
          ...(this.$route.name === 'societies-details' ? { organizationKey: this.societyKey } : ''),
          ...(searchString != '' ? { searchString } : ''),
          ...(searchString != '' ? { searchEmail: searchString } : ''),
          ...(searchString != '' ? { searchJob: searchString } : ''),
        },
      });
      this.results = this.members?.map(item=>item);
      this.isLoading = false;
    },
    async handleLoadOfNewItems({ page }) {
      if (!this.isLoading && this.members.length <= this.total) {
        await this.fetchData({
          page,
          searchString: this.searchString,
          forceAPICall: true,
        });
      }
    },
    handleEditItem(item) {
      this.currentContact = item;
      this.modalTitle = this.$t('contacts.edit-title');
      this.isCreateModalOpended = true;
    },
  },
};
</script>

  <style lang="scss" scoped>
  .members-table {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    flex: 1;
    padding-bottom: 20px;
  }
  </style>
  